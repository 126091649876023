<template>
  <div id="ojiTop">
    <button id="btOjichat" class="btOjichat ojiShown" v-on:click="pc ? null : disableScrollForiOS()"><img src="img/ic_chat.svg" alt="chat"></button>
    <div id="ojiWindow" v-bind:style="pc ? {} : containStyle" class="ojiClosed">
      <div class="ojiTitle">
        <h2>Chatbot</h2>
        <div id="ojiClose" class="close" v-on:click="enableScrollForiOS()"><span></span><span></span></div>
        <!-- クリックしたら closed になる -->
      </div>
      <div class="talk" id="talk">
        <div id="loading" class="ojiLoading">
          <p>Now Loading...</p>
        </div>
        <div class="oji_msg" id="ask_name">
          <span>お名前を、教えてくれないかな？😃</span>
        </div>

      </div>
      <div class="message">
        <input placeholder="メッセージを入力" type="text" id="msgbox"><button id="msg_button"><img src="img/ic_send.svg" alt=""></button>
      </div>
    </div>
  </div>
</template>
<script>

function disableScroll(e) {
  console.log("disableScroll");
  e.preventDefault();
}

function addClass(tagName, className) {
  let element = document.getElementsByTagName(tagName)[0];
  if (element.className !== "") {
    element.className = className + " " + element.className;
  } else {
    element.className = className;
  }
}

function removeClass(tagName, className) {
  let element = document.getElementsByTagName(tagName)[0];
  let re = new RegExp(className + " ?","g");
  element.className = element.className.replace(re, "");
}

export default {
  name: 'Ojichat',
  props: ['containStyle', 'pc'],
  mounted() {
    let exJs = document.createElement('script')
    exJs.setAttribute('src', 'wasm_exec.js')
    document.head.appendChild(exJs)
    // exJs = document.createElement('script')
    // exJs.setAttribute('src', 'https://www.www.gr3.ie/msgfunc.js')
    // document.head.appendChild(exJs)
    exJs = document.createElement('script')
    exJs.setAttribute('src', 'ojichatbot.js')
    document.body.appendChild(exJs)
  },
  methods: {
    disableScrollForiOS: function() {
      // スクロール無効
      document.addEventListener('touchmove', disableScroll, { passive: false });
      // html, body タグに noscroll スタイルを適用
      addClass("body", "noscroll");
      addClass("html", "noscroll");
    },
    enableScrollForiOS: function() {
      // スクロール有効
      document.removeEventListener('touchmove', disableScroll, { passive: false });
      // html, body タグから noscroll スタイルを外す
      removeClass("body", "noscroll");
      removeClass("html", "noscroll");
    }
  }
}

</script>
<style>

      .noscroll{
        overflow: hidden;
      }
      .btOjichat{
        position: fixed;
        bottom: 32px;
        right: 32px;
        z-index: 10000;
        cursor: pointer;
        transition: all .3s ease;
      }
      .btOjichat:hover{
        opacity: .7;
      }
      .btOjichat.ojiShown{
        transition-delay: .3s;
      }
      .btOjichat.ojiHidden{
        opacity: 0;
      }


      .ojiLoading {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1001;
        top: 0;
        left: 0;
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ojiLoading {

      }


      #ojiWindow {
        position: fixed;
        bottom: 32px;
        right: 32px;
        background: #FFF;
        width: 288px;
        height: 430px;
        border-radius: 8px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        z-index: 10000;
        transition: all .5s ease;
        opacity: 1;
        transform: scale(1);
      }
      #ojiWindow.ojiClosed {
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        transform-origin: bottom right;
      }
      #ojiWindow.ojiOpened {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        transform-origin: bottom right;
      }
      .ojiTitle{
        position: relative;
        background: #F75C4D;
        text-align: center;
        padding: 16px;
      }
      .ojiTitle h2{
        font-size: 18px;
        color: #fff;
      }
      .ojiTitle .close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 32px;
        height: 100%;
        cursor: pointer;
      }
      .ojiTitle .close span{
        position: absolute;
        display: block;
        width: 16px;
        height: 2px;
        background: #fff;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      .ojiTitle .close span:first-child{
        transform: rotate(45deg);
      }
      .ojiTitle .close span:last-child{
        transform: rotate(-45deg);
      }


      .talk{
        padding: 24px;
        overflow-y: scroll;
        height: 330px;
      }
      .message{
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
      }
      .message input{
        border: none;
        width: calc(100% - 44px);
        padding: 16px;
        height: 48px;
      }
      .message input::placeholder{
        color: #CBCBCB;
      }

      .message button{
        width: 44px;
        height: 48px;
        padding: 0 10px;
      }
      .message button img{
        width: 20px;
        vertical-align: bottom;
      }
      .oji_msg {
        position: relative;
        margin: 0 0 16px;
      }
      .oji_msg::before{
        content: "";
        position: absolute;
        background: url(/img/ic_ojichan.svg)no-repeat center left;
        width: 34px;
        height: 24px;
      }
      .unko_msg::before{
        content: "";
        position: absolute;
        background: url(/img/ic_unko.svg)no-repeat center left;
        background-size: 25px;
        width: 34px;
        height: 24px;
      }
      .oji_msg span{
        display: inline-block;
        background: #EFEBE5;
        border-radius: 0 10px 10px 10px;
        padding: 8px 16px;
        margin: 0 0 0 42px;
        font-size: 13px;
        font-weight: 500;
        word-break: break-all;
      }
      .your_msg {
        text-align: right;
        margin: 0 0 16px;
      }
      .your_msg span{
        display: inline-block;
        background: #675353;
        color: #fff;
        border-radius: 10px 0 10px 10px;
        padding: 8px 16px;
        font-size: 13px;
        word-break: break-all;
      }



      /* --------------------------
        sp
      -------------------------- */
      @media screen and (max-width: 769px) {

          .btOjichat{
            bottom: 20px;
            right: 20px;
          }
          .btOjichat img{
            width: 50px;
          }
          #ojiWindow {
            bottom: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            border-radius: 0;
          }

          #ojiWindow.ojiClosed {
            transform-origin: calc(100% - 32px) calc(100% - 32px);
          }
          #ojiWindow.ojiOpened {
            transform-origin: calc(100% - 32px) calc(100% - 32px);
          }
          .talk{
            height: calc(100% - 101px);
          }
          .message input{
            font-size: 16px;
            height: 50px;
          }
          
      }


</style>
